.buttonHome{
    background-color: #D0BE50;
    color: #66021C;
    padding: 10px 20px;
    margin-top: 1.5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    border-color: #66021C;
    cursor: pointer;
}
.homeDiv{
    width: 100%;
    align-items: center;
    min-height: 100vw;
}
.link{
    text-decoration: none;
    color: #66021C;
}
.buttons{
    display: flex;
    flex-direction: column;
}
.welcome{
    font-weight: bold;
    font-size: x-large;
}
.welcomeMob{
    font-weight: bold;
    font-size: medium;
}